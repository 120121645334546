<template>
    <main class="income">
        <router-view class="income__data" />
    </main>
</template>

<script>
    export default {
        name: "Income",
    }
</script>
